import styled from "@emotion/styled";
import React from "react";

import theme from "../../theme";

const HorizontalRuleWrapper = styled.div`
  margin: ${theme.spacing("s")} 0;
`;

function HorizontalRule({ ...props }) {
  return (
    <HorizontalRuleWrapper {...props}>
      <svg
        height="3"
        viewBox="0 0 32 3"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        fill={theme.color("secondary")}
      >
        <path d="m22 1h10v1h-10v1h-22v-3h22z" fillRule="evenodd" />
      </svg>
    </HorizontalRuleWrapper>
  );
}

HorizontalRule.propTypes = {};

HorizontalRule.defaultProps = {};

export default HorizontalRule;
