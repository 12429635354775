import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { complement, isEmpty } from "ramda";
import React from "react";

import ContactHighlight from "../../components/ContactHighlight";
import Heading from "../../components/Heading";
import Hero from "../../components/Hero/v3";
import Introduction from "../../components/Introduction";
import Modular from "../../components/Modular";
import SEO from "../../components/SEO";
import Text from "../../components/Text";
import { updateItem } from "../../types";

const notEmpty = complement(isEmpty);

const Update = ({
  data: {
    data: { update, formLabels },
  },
}) => {
  const {
    heroImage,
    title,
    description,
    tagline,
    date,
    contactHighlight,
    content,
    seoMetaTags,
  } = update;

  return (
    <>
      <SEO tags={seoMetaTags} />
      <Hero
        title={
          <Heading color="secondary" size={1}>
            {title}
          </Heading>
        }
        image={heroImage}
        introduction={<Introduction>{tagline}</Introduction>}
        content={<Text html>{description}</Text>}
        date={date}
        visible
      />

      {content.filter(notEmpty).map((item) => {
        if (item.type == "DatoCMS_SignupRecord") {
          item.formLabels = formLabels;
        }
        return <Modular key={item.id} {...item} type={item.type} />;
      })}

      {contactHighlight && <ContactHighlight {...contactHighlight} />}
    </>
  );
};

Update.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      update: PropTypes.shape(updateItem).isRequired,
      formLabels: PropTypes.object,
    }).isRequired,
  }).isRequired,
};

export default Update;

export const query = graphql`
  query getUpdate($slug: String!) {
    data: dato {
      update(filter: { slug: { eq: $slug } }) {
        seoMetaTags: _seoMetaTags {
          tag
          content
          attributes
        }
        heroImage {
          data: responsiveImage(
            imgixParams: { fit: crop, h: 710, fm: jpg, auto: format }
          ) {
            ...responsiveImageFragment
          }
        }
        title
        description
        tagline
        date
        slug
        updateType {
          name
          id
        }
        contactHighlight {
          ...contactHighlightFragment
        }
        content {
          ...basicTitleDescriptionFragment
          ...callToActionFragment
          ...imageFragment
          ...videoFragment
          ...quoteFragment
          ...textColumnFragment
          ...textImageFragment
          ...imageCarouselFragment
          ...signupFragment
          ...downloadFragment
        }
      }
      formLabels: global {
        newsletterButtonText
        beforePrivacyPolicy
        afterPrivacyPolicy
        privacyPolicyLinkText
        privacyPolicyPath
      }
    }
  }
`;
