import styled from "@emotion/styled";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../../theme";
import { format } from "../../../utils/date";
import mq from "../../../utils/mediaQuery";
import LayoutContentWrapper from "../../ContentWrapper";
import HorizontalRule from "../../HorizontalRule";
import Image, { ImageType } from "../../ImageComponent";
import { caption } from "../../Typography";
const VERTICAL_CONTENT_OFFSET_SMALL = 250;
const VERTICAL_CONTENT_OFFSET_LARGE = 340;
const BACKGROUND_HEIGHT_SMALL = 360;
const BACKGROUND_HEIGHT_LARGE = 710;

const Wrapper = styled.section`
  position: relative;
  padding: ${VERTICAL_CONTENT_OFFSET_SMALL}px ${theme.space("xs")} 0;

  ${mq("2")} {
    padding-top: ${VERTICAL_CONTENT_OFFSET_LARGE}px;
  }
`;

const BackgroundWrapper = styled.div`
  width: 100%;
  height: ${BACKGROUND_HEIGHT_SMALL}px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  ${mq("2")} {
    height: ${BACKGROUND_HEIGHT_LARGE}px;
  }
`;

const StyledImage = styled(Image)`
  height: 100%;
  img {
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
`;

const StyledLayoutContentWrapper = styled(LayoutContentWrapper)`
  background: ${theme.color("background")};
`;

const TitleWrapper = styled(motion.div)`
  padding-top: ${theme.space("m")};

  ${mq("2")} {
    padding-top: ${theme.space("xl")};
    padding-bottom: 0;
  }
`;

const BottomWrapper = styled.div`
  padding-bottom: ${theme.space("m")};

  ${mq("2")} {
    display: flex;
    justify-content: space-between;
    padding: ${theme.space("xl")} 0;
  }
`;

const IntroductionWrapper = styled(motion.div)`
  ${mq("2")} {
    flex: 1 1 50%;
    margin-right: ${theme.space("xl")};

    > * {
      margin-top: 0;
    }
  }
`;

const ContentWrapper = styled(motion.div)`
  ${mq("2")} {
    flex: 1 1 50%;

    > * {
      margin-top: 0;
    }
  }

  ul {
    margin-top: 5px;
  }
  li {
    list-style-type: disc;
  }
`;

const PostDate = styled.span`
  ${caption};
`;

const MotionWrapper = styled(motion.div)`
  position: relative;
`;

function HeroV3({
  title,
  content,
  introduction,
  image,
  date,
  visible = false,
  ...props
}) {
  const isSSR = typeof window === "undefined";

  const variants = {
    show: {
      y: 0,
      transition: theme.get("animation.staggeredReveal.transition"),
    },
    hide: {
      y: "30px",
      transition: theme.get("animation.staggeredReveal.transition"),
    },
  };

  const staggerVariants = {
    hide: { opacity: 0, y: 40 },
    show: {
      opacity: 1,
      y: 0,
      transition: theme.get("animation.reveal"),
    },
  };

  return (
    <Wrapper {...props}>
      <BackgroundWrapper>
        <StyledImage {...image} layout="responsive" />
      </BackgroundWrapper>

      <MotionWrapper
        initial={isSSR ? false : "hide"}
        animate={visible ? "show" : "hide"}
        variants={variants}
      >
        <StyledLayoutContentWrapper columns={10}>
          <LayoutContentWrapper columns={8}>
            <TitleWrapper variants={staggerVariants}>{title}</TitleWrapper>
            <BottomWrapper>
              {introduction && (
                <IntroductionWrapper variants={staggerVariants}>
                  {introduction}
                  <HorizontalRule />
                  {date && <PostDate>{format(new Date(date))}</PostDate>}
                </IntroductionWrapper>
              )}
              {content && (
                <ContentWrapper variants={staggerVariants}>
                  {content}
                </ContentWrapper>
              )}
            </BottomWrapper>
          </LayoutContentWrapper>
        </StyledLayoutContentWrapper>
      </MotionWrapper>
    </Wrapper>
  );
}

HeroV3.propTypes = {
  title: PropTypes.node.isRequired,
  introduction: PropTypes.node,
  content: PropTypes.node,
  image: PropTypes.shape(ImageType),
  date: PropTypes.string,
  visible: PropTypes.bool,
};

HeroV3.defaultProps = {};

export default HeroV3;
